import AccountMenu from '@ecomm/nav/Account/Account';
import Cart from '@ecomm/nav/Cart/Cart';
import GeoPicker from '@ecomm/nav/GeoPicker/GeoPicker';
import HamburgerNav from '@ecomm/nav/HamburgerNav/HamburgerNav';
import Nav from '@ecomm/nav/Nav';
import ProductSubNav from '@ecomm/nav/ProductTopNav/ProductSubNavRow';
import AppStoreModule from '../../../../apps/freeform/modules/champ-ui/AppStoreModule/AppStoreModule';
import { ThemedCompareModule } from '../../../../apps/freeform/modules/champ-ui/CompareModule/CompareModule';
import DeviceCtaHero from '../../../../apps/freeform/modules/champ-ui/DeviceCtaHero/DeviceCtaHero';
import { DisciplineTilesWithModal } from '../../../../apps/freeform/modules/champ-ui/DisciplineTilesWithModal/DisciplineTilesWithModal';
import FiftyFiftyHero from '../../../../apps/freeform/modules/champ-ui/FiftyFiftyHero';
import FullWidthMedia from '../../../../apps/freeform/modules/champ-ui/FullWidthMedia/FullWidthMedia';
import Headband from '../../../../apps/freeform/modules/champ-ui/Headband';
import HeroTiles from '../../../../apps/freeform/modules/champ-ui/HeroTiles/HeroTiles';
import IOSCtaHero from '../../../../apps/freeform/modules/champ-ui/IOSCtaHero/IOSCtaHero';
import MediaCarousel from '../../../../apps/freeform/modules/champ-ui/MediaCarousel/MediaCarousel';
import OutcomesHero from '../../../../apps/freeform/modules/champ-ui/OutcomesHero/OutcomesHero';
import ProductGrid from '../../../../apps/freeform/modules/champ-ui/ProductGrid/ProductGrid';
import TableOfContents from '../../../../apps/freeform/modules/champ-ui/TableOfContents/TableOfContents';
import { ToggledCarousels } from '../../../../apps/freeform/modules/champ-ui/ToggledCarousels/ToggledCarousels';
import ViewportHero from '../../../../apps/freeform/modules/champ-ui/ViewportHero/ViewportHero';
import Header from '../../../../apps/freeform/modules/onewellness-ui/Header';
import CollectionCardRow from '../../../../apps/freeform/modules/prospects-ui/CollectionCardRow/CollectionCardRow';
import DisciplineList from '../../../../apps/freeform/modules/prospects-ui/DisciplineList/DisciplineList';
import HeroBanner from '../../../../apps/freeform/modules/prospects-ui/HeroBanner/HeroBanner';
import MovementLibrary from '../../../../apps/freeform/modules/prospects-ui/MovementLibrary/MovementLibrary';
import TabsListing from '../../../../apps/freeform/modules/prospects-ui/TabsListing';
import TestComponent from '../../../../apps/freeform/modules/TestComponent';

// Key should match `component` value in Component: JSON content type
const JsonComponentMap = {
  ['CollectionCardRow (PROSPECTS)']: CollectionCardRow,
  ['CompareModule (CHAMP)']: ThemedCompareModule,
  ['DeviceCtaHero (CHAMP)']: DeviceCtaHero,
  ['DisciplineList (PROSPECTS)']: DisciplineList,
  ['DisciplineTilesWithModal (CHAMP)']: DisciplineTilesWithModal,
  ['FiftyFiftyHero (CHAMP)']: FiftyFiftyHero,
  ['Headband (CHAMP)']: Headband,
  ['HeroBanner (PROSPECTS)']: HeroBanner,
  ['HeroTiles (CHAMP)']: HeroTiles,
  ['MediaCarousel (CHAMP)']: MediaCarousel,
  ['TableOfContents (CHAMP)']: TableOfContents,
  ['TabsListing (PROSPECTS)']: TabsListing,
  ['TestComponent (TAKO)']: TestComponent,
  ['ToggledCarousels (CHAMP)']: ToggledCarousels,
  ['ViewportHero (CHAMP)']: ViewportHero,
  ['ProductGrid (CHAMP)']: ProductGrid,
  ['OutcomesHero (CHAMP)']: OutcomesHero,
  ['IOSCtaHero (CHAMP)']: IOSCtaHero,
  ['AppStoreModule (CHAMP)']: AppStoreModule,
  ['Header (ONEWELLNESS)']: Header,
  ['FullWidthMedia (CHAMP)']: FullWidthMedia,
  ['MovementLibrary (PROSPECTS)']: MovementLibrary,
  ['Nav (PROSPECTS)']: Nav,
  ['Nav: ProductSubNav (PROSPECTS)']: ProductSubNav,
  ['Nav: GeoPicker (PROSPECTS)']: GeoPicker,
  ['Nav: Account Menu (PROSPECTS)']: AccountMenu,
  ['Nav: Cart (PROSPECTS)']: Cart,
  ['Nav: Hamburger Menu (PROSPECTS)']: HamburgerNav,
};

export default JsonComponentMap;
