import { spacing } from '@pelotoncycle/design-system';
import type { ProductRecommendationsCohort } from '@content/client/www/product-recommendations/models';
import { FiltersStyle } from '@ecomm/product-recommendations/models/FiltersStyle';
import { CtaType } from '@ecomm/product-recommendations/models/TileComponentProps';
import type { SupportedTreatmentType } from '@ecomm/product-recommendations/models/treatment';
import type { TreatmentOptions } from '@ecomm/product-recommendations/models/TreatmentOptions';
import GridLoadingComponent from '@ecomm/product-recommendations/Views/LoadingComponents/GridLoadingComponent';
import NestedGridLoadingComponent from '@ecomm/product-recommendations/Views/LoadingComponents/NestedGridLoadingComponent';
import CarouselModuleComponent from '@ecomm/product-recommendations/Views/ModuleComponents/CarouselModuleComponent';
import GridModuleComponent from '@ecomm/product-recommendations/Views/ModuleComponents/GridModuleComponent';
import NestedHeroModuleComponent from '@ecomm/product-recommendations/Views/ModuleComponents/NestedHeroModuleComponent';
import BuildTimeGridTileComponent from '@ecomm/product-recommendations/Views/TileComponents/BuildTimeGridTileComponent';
import GridTileComponent from '@ecomm/product-recommendations/Views/TileComponents/GridTileComponent';
import NestedHeroTileComponent, {
  BuildTimeNestedHeroTileComponent,
} from '@ecomm/product-recommendations/Views/TileComponents/NestedHeroTileComponent';

export const gridTreatmentMap: Record<SupportedTreatmentType, TreatmentOptions> = {
  ['Product Recommendations Upsell Grid']: {
    columnCount: {
      mobile: 2,
      desktop: 3,
    },
    ctaType: CtaType.UpsellCtaGroup,
    headerAlignment: 'center',
    headerGap: spacing[48],
    horizontalPadding: {
      mobile: spacing[16],
      tablet: spacing[64],
      desktop: 0,
    },
    verticalPadding: {
      mobile: spacing[48],
      desktop: spacing[64],
    },
    minHeight: 600,
    headerPadding: 0,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
    moduleComponent: GridModuleComponent,
    tileComponent: GridTileComponent,
    loadingComponent: GridLoadingComponent,
    buildTimeTileComponent: BuildTimeGridTileComponent,
  },
  ['Deals Page Product Grid']: {
    columnCount: {
      mobile: 2,
      desktop: 4,
    },
    headerAlignment: 'left',
    headerGap: { mobile: spacing[24], desktop: spacing[32] },
    horizontalPadding: {
      mobile: spacing[16],
      tablet: spacing[64],
      desktop: spacing[40],
    },
    verticalPadding: {
      mobile: spacing[24],
      desktop: spacing[40],
    },
    minHeight: 600,
    headerPadding: 0,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
    moduleComponent: GridModuleComponent,
    tileComponent: GridTileComponent,
    loadingComponent: GridLoadingComponent,
    buildTimeTileComponent: BuildTimeGridTileComponent,
  },
  ['Toggled Deals Page Product Grid']: {
    columnCount: {
      mobile: 2,
      desktop: 4,
    },
    centerCards: true,
    headerAlignment: 'left',
    headerGap: { mobile: spacing[24], desktop: spacing[32] },
    horizontalPadding: {
      mobile: spacing[16],
      tablet: spacing[64],
      desktop: spacing[40],
    },
    verticalPadding: {
      mobile: spacing[24],
      desktop: spacing[40],
    },
    minHeight: 600,
    headerPadding: 0,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
    filtersStyle: FiltersStyle.Toggle,
    moduleComponent: GridModuleComponent,
    tileComponent: GridTileComponent,
    loadingComponent: GridLoadingComponent,
    buildTimeTileComponent: BuildTimeGridTileComponent,
  },
  ['Package Upsell In Overview']: {
    columnCount: {
      mobile: 2,
      desktop: 2,
    },
    headerAlignment: 'left',
    headerGap: { mobile: spacing[16], desktop: spacing[16] },
    horizontalPadding: 0,
    verticalPadding: {
      mobile: `0 ${spacing[24]}`,
      desktop: `0 ${spacing[32]}`,
    },
    minHeight: 0,
    showSectionDivider: true,
    headerPadding: `${spacing[16]} 0`,
    removeOuterContainerTheme: true,
    eyebrowHeadingSize: {
      mobile: 'medium',
      desktop: 'small',
    },
    moduleComponent: GridModuleComponent,
    tileComponent: GridTileComponent,
    loadingComponent: GridLoadingComponent,
    buildTimeTileComponent: BuildTimeGridTileComponent,
  },
  ['PLP Product Grid']: {
    columnCount: {
      mobile: 2,
      desktop: 4,
    },
    headerAlignment: 'left',
    headerGap: {
      mobile: spacing[24],
      desktop: spacing[32],
    },
    horizontalPadding: {
      mobile: spacing[16],
      tablet: spacing[64],
      desktop: spacing[40],
    },
    verticalPadding: {
      mobile: spacing[48],
      desktop: spacing[64],
    },
    centerCards: true,
    minHeight: 600,
    headerPadding: 0,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
    filtersStyle: FiltersStyle.Toggle,
    moduleComponent: GridModuleComponent,
    tileComponent: GridTileComponent,
    loadingComponent: GridLoadingComponent,
    buildTimeTileComponent: BuildTimeGridTileComponent,
  },
  ['Carousel Product Grid']: {
    columnCount: {
      mobile: 2,
      desktop: 4,
    },
    headerAlignment: 'left',
    headerGap: {
      mobile: spacing[8],
    },
    horizontalPadding: '0',
    verticalPadding: {
      mobile: spacing[48],
      desktop: spacing[64],
    },
    minHeight: 300,
    headerPadding: 0,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
    moduleComponent: CarouselModuleComponent,
    tileComponent: GridTileComponent,
    loadingComponent: GridLoadingComponent,
    buildTimeTileComponent: BuildTimeGridTileComponent,
  },
  'Nested Hero Product Grid': {
    columnCount: {
      mobile: 2,
      desktop: 4,
    },
    headerAlignment: 'left',
    headerGap: 0,
    horizontalPadding: 0,
    verticalPadding: 0,
    minHeight: 88,
    headerPadding: 0,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
    moduleComponent: NestedHeroModuleComponent,
    tileComponent: NestedHeroTileComponent,
    loadingComponent: NestedGridLoadingComponent,
    buildTimeTileComponent: BuildTimeNestedHeroTileComponent,
  },
  ['Filter Buttons Product Grid']: {
    columnCount: {
      mobile: 2,
      desktop: 4,
    },
    headerAlignment: 'left',
    headerGap: { mobile: spacing[24], desktop: spacing[32] },
    horizontalPadding: {
      mobile: spacing[16],
      tablet: spacing[64],
      desktop: spacing[40],
    },
    verticalPadding: {
      mobile: spacing[24],
      desktop: spacing[40],
    },
    minHeight: 600,
    headerPadding: 0,
    eyebrowHeadingSize: {
      mobile: 'small',
      desktop: 'small',
    },
    filtersStyle: FiltersStyle.Buttons,
    moduleComponent: GridModuleComponent,
    tileComponent: GridTileComponent,
    loadingComponent: GridLoadingComponent,
    buildTimeTileComponent: BuildTimeGridTileComponent,
  },
};

const toCenteredColumnCount = (options: TreatmentOptions, tileCount: number) => ({
  mobile: Math.min(tileCount, options.columnCount.mobile),
  desktop: Math.min(tileCount, options.columnCount.desktop),
});

const toGridOptions = (
  cohort: ProductRecommendationsCohort,
  productCount: number,
): TreatmentOptions => {
  const options = gridTreatmentMap[cohort.treatment];
  const tileCount =
    productCount + cohort.marketingTiles.length + cohort.marketingTilesWithModal.length;

  const columnCount = options.centerCards
    ? toCenteredColumnCount(options, tileCount)
    : options.columnCount;

  return {
    ...options,
    columnCount,
  };
};

export default toGridOptions;
