import {
  Button,
  Flex,
  Label,
  spacing,
  Support,
  BreakpointWidths,
} from '@pelotoncycle/design-system';
import React, { useState } from 'react';
import { useTracking } from 'react-tracking';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { toCloudinarySrc } from '@peloton/images/toCloudinarySrc';
import { TrackingEvent } from '@ecomm/analytics/models';
import { Link } from '@ecomm/internationalize-ui';
import { MODAL_CLOSE_TRANSITION_TIME } from '@ecomm/modal/ScrollFromBottomModalStyles';
import { isProductSparePart } from '@ecomm/product-recommendations/models/checkProductType';
import type { TileComponentProps } from '@ecomm/product-recommendations/models/TileComponentProps';
import { toGridTheme } from '@ecomm/product-recommendations/utils/theme';
import { toTileButtons } from '@ecomm/product-recommendations/utils/toTileButtons';
import useDetailsUrl from '@ecomm/product-recommendations/utils/useDetailsUrl';
import AddToCartMutationWrapper from '@ecomm/product-recommendations/Views/AddToCartMutationWrapper';
import FinancingText from '@ecomm/product-recommendations/Views/FinancingText';
import PackageContentsButton from '@ecomm/product-recommendations/Views/Grid/PackageContentsButton';
import {
  ImageContainer,
  TileMedia,
  TileButton,
  TileDescription,
  TileLink,
  TileWrapper,
} from '@ecomm/product-recommendations/Views/Grid/SharedTileComponents';
import PriceDisplay from '@ecomm/product-recommendations/Views/PriceDisplay';
import ProductPills from '@ecomm/product-recommendations/Views/ProductPills';
import { QuickViewModal } from '@ecomm/product-recommendations/Views/QuickViewModal';
import {
  setUrlQueryParams,
  deleteUrlQueryParam,
  getExistingQueryValueFor,
} from '@page-builder/utils/helpers/urlParams';

// This component is largely ported from Grid/GridProductTile
const GridTileComponent: React.FC<React.PropsWithChildren<TileComponentProps>> = ({
  product,
  ctaType,
  themeName,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalKey, setModalKey] = useState('closed');

  const { name, imageUrl, overrideImageUrl, factoid, packageContents = [] } = product;

  const productUrl = useDetailsUrl(product);
  const productImageUrl = overrideImageUrl || imageUrl;
  const { imageBackground } = toGridTheme(themeName);

  const isSparePart = isProductSparePart(product);

  const isLink = !ctaType;
  const isMobile = useMedia(`(max-width: ${BreakpointWidths.tablet}px)`);
  const breakpoint = isMobile ? 'mobile' : 'desktop';

  const { trackEvent } = useTracking();

  const clickedLinkEventProps = {
    event: TrackingEvent.ClickedLink,
    properties: {
      unitName: name,
      linkName: 'Quick view',
      linkTo: '',
    },
  };

  // TODO: Move all spare parts and modal logic into a separate location
  React.useEffect(() => {
    const sparePart = getExistingQueryValueFor('sparePart');
    if (sparePart && product.slug === sparePart) {
      setModalOpen(true);
    }
  }, [product.slug]);

  const setUrlProductSlug = () => {
    setUrlQueryParams('sparePart', product.slug);
  };

  const resetUrlProductSlug = () => {
    deleteUrlQueryParam('sparePart');
  };

  const createModalEventProps = (
    eventName: 'Modal Opened' | 'Modal Closed',
    modalTrigger: string,
  ) => {
    return {
      event: eventName,
      properties: {
        productInterest: name,
        propertyType: 'Web',
        unitName: `${name} Quick View Modal`,
        unitPlacement: 'Modal',
        modalTrigger,
      },
    };
  };

  const onSelect: React.MouseEventHandler = e => {
    if (isSparePart) {
      setUrlProductSlug();
    }

    if (ctaType) {
      e.preventDefault();
      trackEvent(clickedLinkEventProps);
      setModalOpen(true);
      setModalKey('open');
      trackEvent(createModalEventProps('Modal Opened', `${name} 'Quick view' CTA click`));
    } else {
      trackEvent({
        event: TrackingEvent.ClickedLink,
        properties: {
          unitName: name,
          linkName: '',
          linkTo: productUrl,
        },
      });
    }
  };

  const buttons = ctaType
    ? toTileButtons(ctaType, {
        onQuickView: onSelect,
        onQuickAdd: onSelect,
        onSeeDetails: onSelect,
      })
    : [];

  const showButtons = ctaType && buttons.length > 0;

  const onCloseModal = () => {
    resetUrlProductSlug();
    setModalOpen(false);
    // The key change is used to reset the input elements in the
    // modal on closing / reopening the modal.
    // The timeout is required to prevent the modal from closing abruptly (leading to a flash)
    // which will happen if the key for the modal changes before the closing transition
    // animation completes
    setTimeout(() => setModalKey('closed'), MODAL_CLOSE_TRANSITION_TIME);
    trackEvent(
      createModalEventProps('Modal Closed', `User closed ${name} 'Quick view' modal`),
    );
  };

  return (
    <>
      <TileWrapper data-test-id="grid-tile-component">
        {isLink ? (
          <TileLink href={productUrl} onClick={onSelect}>
            {name}
          </TileLink>
        ) : (
          <TileButton onClick={onSelect}>{name}</TileButton>
        )}
        <Flex flexDirection="column" gap={spacing[16]}>
          <ImageContainer
            background={imageBackground}
            borderRadius={spacing[4]}
            position="relative"
            flexDirection="column"
            justifyContent={isSparePart ? 'space-between' : 'flex-start'}
          >
            <ProductPills product={product} />
            <TileMedia
              width="100%"
              height="100%"
              objectFit="contain"
              media={{
                mobile: toCloudinarySrc({ src: productImageUrl, height: 600 }),
                alt: name,
                type: 'image',
                loading: 'lazy',
              }}
            />
            {showButtons && (
              <Flex
                justifyContent="space-between"
                gap={spacing[16]}
                padding={{
                  mobile: `0 ${spacing[8]} ${spacing[8]}`,
                  desktop: `0 ${spacing[16]} ${spacing[16]}`,
                }}
              >
                {buttons
                  .filter(button =>
                    button.displayLocations[breakpoint].includes('imageContainer'),
                  )
                  .map((button, index) => (
                    <StyledButton
                      key={`${button.id}-${index}`}
                      size={isMobile ? 'small' : button.size}
                      color="dark"
                      width="adaptive"
                      variant={button.variant}
                      onClick={button.onClick}
                      data-test-id={`${button.id}-cta`}
                      style={{
                        fontWeight: 600,
                        fontSize: spacing[16],
                      }}
                    >
                      {button.text}
                    </StyledButton>
                  ))}
              </Flex>
            )}
            {packageContents.length > 0 && (
              <PackageContentsButton product={product} themeName={themeName} />
            )}
          </ImageContainer>
          <Flex
            flexDirection="column"
            gap={spacing[8]}
            flexGrow={1}
            justifyContent="space-between"
          >
            <Label size="large">{name}</Label>
            <Flex flexDirection="column" gap={spacing[8]}>
              <Flex flexDirection="column" gap={spacing[4]}>
                <Support size="large" data-test-id="grid-product-tile-price">
                  <PriceDisplay product={product} />
                </Support>
                {factoid && <TileDescription>{factoid}</TileDescription>}
                <FinancingText product={product} />
              </Flex>
              {isSparePart && (
                <TileDescription truncated>{product.description}</TileDescription>
              )}
              {showButtons &&
                buttons
                  .filter(button =>
                    button.displayLocations[breakpoint].includes('belowDescription'),
                  )
                  .map((button, index) => (
                    <StyledLink
                      size="small"
                      variant="body"
                      data-test-id={`${button.id}-cta-below-description`}
                      key={`${button.id}-${index}`}
                    >
                      {button.text}
                    </StyledLink>
                  ))}
            </Flex>
          </Flex>
        </Flex>
      </TileWrapper>
      <AddToCartMutationWrapper product={product}>
        {addToCartMutation => (
          <QuickViewModal
            key={modalKey}
            product={product}
            isOpen={modalOpen}
            onClose={onCloseModal}
            addToCartMutation={addToCartMutation}
            isInCart={false}
          />
        )}
      </AddToCartMutationWrapper>
    </>
  );
};

const StyledButton = styled(Button)`
  position: relative;
  z-index: 3;
`;

const StyledLink = styled(Link)`
  width: fit-content;
`;

export default GridTileComponent;
