import { Body, Flex, grey, Headline, spacing } from '@pelotoncycle/design-system';
import { ResponsiveColumns } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import type { TypeComponentGenericListFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import CohortGridCard from './CohortGridCard';

type Props = TypeComponentGenericListFields;

const CohortGridEntry: React.FC<React.PropsWithChildren<Props>> = ({ text, items }) => {
  const { headline, support } = getGenericTextNodes(text, ['headline', 'support']);

  return (
    <Flex
      display="flex"
      verticalPadding={{
        mobile: spacing[24],
        tablet: spacing[64],
      }}
      backgroundColor={grey[10]}
      justifyContent="center"
      flexDirection="column"
    >
      <StyledResponsiveColumns
        headlineText={<Headline size="small">{headline}</Headline>}
        bodyText={<Body size="small">{support}</Body>}
        items={items.map((item, index) => (
          <CohortGridCard item={item} key={index} />
        ))}
      />
    </Flex>
  );
};

const StyledResponsiveColumns = styled(ResponsiveColumns)`
  padding: 0 ${spacing[16]};
`;

export default CohortGridEntry;
