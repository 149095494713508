import {
  Flex,
  Label,
  Support,
  Media,
  spacing,
  grey,
  Button,
  Container,
  white,
} from '@pelotoncycle/design-system';
import { useRouter } from 'next/router';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled, { createGlobalStyle } from 'styled-components';
import { useErrorReporter } from '@peloton/error-reporting';
import useSubHeaderReference from '@acme-ui/global/hooks/useSubHeaderReference';
import useCartViewedAnalytics from '@ecomm/cart-next/analytics/useCartViewedAnalytics';
import useCTCartViewedAnalytics from '@ecomm/cart-next/analytics/useCTCartViewedAnalytics';
import { useOpenCartPanel } from '@ecomm/cart-next/context/CartContext';
import useCartWithVariables from '@ecomm/cart-next/hooks/useCartWithVariables';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import {
  toMediaProps,
  toCtaFields,
  getTextFieldsWithRequiredKeys,
  media as mediaHelpers, // Renamed to avoid shadowing
} from '@page-builder/utils/helpers';
import Markdown from '@page-builder/utils/Markdown';
import { useShouldShowSubnav } from './utils';

const PreCartSubnav: React.FC<
  React.PropsWithChildren<TypeComponentGenericTextWithMediaFields>
> = ({ name, text, media, ctas }) => {
  const headbandRef = useSubHeaderReference();
  const { errorReporter } = useErrorReporter();
  const openCartPanel = useOpenCartPanel();
  const mediaProps = media && toMediaProps(media);
  const cartCta = ctas && toCtaFields(ctas)[0];
  const { data: ctCart, loading: ctCartLoading } = useGetCartItems({
    fetchPolicy: 'cache-first',
  });
  const { data: legacyCartData, loading: legacyCartLoading } = useCartWithVariables({
    fetchPolicy: 'cache-first',
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });
  const { trackEvent } = useTracking();
  const { trackCartViewed } = useCartViewedAnalytics();
  const { trackCTCartViewed } = useCTCartViewedAnalytics();
  const router = useRouter();
  const {
    eyebrow: productTitle,
    headline: productSubhead,
  } = getTextFieldsWithRequiredKeys(['eyebrow', 'headline'], text);
  const shouldShowSubnavContents = useShouldShowSubnav(router, ctCart, legacyCartData);
  const hasFinishedLoading = !legacyCartLoading && !ctCartLoading && router.isReady;
  const hideEntireSubnav = !shouldShowSubnavContents && hasFinishedLoading;

  if (hideEntireSubnav) {
    return null;
  }

  const handleOnClick = () => {
    trackEvent({
      event: 'Clicked View Cart',
      properties: {
        parentType: 'Component: Generic Text with Media',
        parent: name,
        unitName: cartCta?.name,
        linkName: cartCta?.text,
        linkTo: cartCta?.link,
      },
    });
    openCartPanel();
    // check which version of the cart is being used before tracking
    ctCart?.lineItems.length && trackCTCartViewed(ctCart);
    legacyCartData?.cart?.numberOfItems && trackCartViewed(legacyCartData);
  };

  return (
    <StyledSubnav
      ref={headbandRef}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      padding={{ mobile: `${spacing[16]}`, desktop: `0 ${spacing[24]}` }}
      backgroundColor={white}
      position={{ mobile: 'fixed', desktop: 'sticky' }}
    >
      {shouldShowSubnavContents ? (
        <>
          <Flex>
            {mediaProps && (
              <Container display={{ mobile: 'none', desktop: 'contents' }}>
                <Media media={mediaProps} height={spacing[80]} width={spacing[80]} />
              </Container>
            )}
            <Flex
              flexDirection="column"
              justifyContent="center"
              horizontalPadding={{ mobile: '0', desktop: `${spacing[16]} 0` }}
            >
              {productTitle && (
                <Label size="large">
                  <Markdown content={productTitle} />
                </Label>
              )}
              {productSubhead && (
                <Support size="large">
                  <Markdown content={productSubhead} />
                </Support>
              )}
            </Flex>
          </Flex>
          {cartCta?.text && (
            <CartCTA
              color={cartCta?.color}
              variant={cartCta?.variant}
              size="medium"
              text={cartCta.text}
              width="adjustable"
              onClick={handleOnClick}
            />
          )}
        </>
      ) : (
        <LoadingSpacer data-test-id="loading-spacer" />
      )}
      <GlobalStyle />
    </StyledSubnav>
  );
};

const CartCTA = styled(Button)`
  padding: 0 ${spacing[16]} !important;
`;

const StyledSubnav = styled(Flex)`
  bottom: 0;
  width: 100%;
  border-top: 1px solid ${grey[40]};
  z-index: 5;
  ${mediaHelpers.desktop` 
    top: 0;
    border-top: unset;
    bottom: unset;
    border-bottom: 1px solid ${grey[40]};
  `}
`;

const LoadingSpacer = styled.div`
  height: ${spacing[80]};
`;

const GlobalStyle = createGlobalStyle`
  #footer {
    padding-bottom: ${spacing[80]};
    ${mediaHelpers.desktop` 
      padding-bottom: 0;
    `}
  }
`;

export default PreCartSubnav;
