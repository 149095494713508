import { Button, Eyebrow, SingleSelect, spacing } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { useGetTextFormatter } from '@peloton/next/hooks/useFormattedText';
import SelectWithDesignSystemErrorMsg from '@ecomm/forms/SelectWithDesignSystemErrorMsg';
import { useProductRecommendationsCopyKey } from '@ecomm/product-recommendations/utils/useProductRecommendationsCopyKey';

import { isProductBundle } from '../models/checkProductType';
import type { Attribute, AttributeOption, Product } from '../models/Product';

import {
  allSelectionsMade,
  toFormSelectOption,
  toFormSelectOptionDS,
  getAttributeToSelectedSlugMap,
  disableDuplicateOptionSelection,
} from '../utils/productUtils';
import type { SelectionType } from '../utils/useConfigureProductFormData';

type Props = {
  product: Product;
  selections: SelectionType;
  onFormSubmit: () => Promise<void>;
  toSelectItemHandler: (
    attribute: Attribute,
    attributeIndex: number,
  ) => (e: React.FormEvent<HTMLSelectElement>) => void;
  quickAddCTA: string;
  addToCartCTA: string;
  loading: boolean;
  isQuickViewModal?: boolean;
};

export const ConfigureProductFormSelections: React.FC<React.PropsWithChildren<Props>> = ({
  product,
  selections,
  onFormSubmit,
  toSelectItemHandler,
  quickAddCTA,
  addToCartCTA,
  loading,
  isQuickViewModal = false,
  children,
}) => {
  const StyledWrapper = isQuickViewModal ? QuickViewSelectWrapper : SelectWrapper;
  const [clickedATC, setClickedATC] = React.useState(false);
  const selectAttributeRaw = useProductRecommendationsCopyKey('selectAttribute');
  const isRequiredCopy = useProductRecommendationsCopyKey('isRequired');
  const selectedLabel = useProductRecommendationsCopyKey('dropdownOptionSelected');
  const toFormattedText = useGetTextFormatter();

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (allSelectionsMade(product, selections)) {
      onFormSubmit();
    }
    setClickedATC(true);
  };

  const selectedSlugsMap = getAttributeToSelectedSlugMap(product, selections);

  return (
    <StyledForm onSubmit={handleOnSubmit}>
      {product.attributes.map((attribute, attributeIndex) => {
        const selectedSlug: string = isProductBundle(product)
          ? selections[attributeIndex].selections[0].option
          : selections[attribute.slug];

        const selectedItem = attribute.options.find(
          ({ slug }) => slug === selectedSlug,
        ) as AttributeOption;

        const displayValue: string = isQuickViewModal
          ? selectedItem?.name
          : selectedItem?.slug;

        const selectAttributeCopy = toFormattedText(selectAttributeRaw, {
          name: attribute.name,
        });

        const itemsForSelect = disableDuplicateOptionSelection(
          attribute.options.map(toFormSelectOptionDS),
          selectedSlugsMap[attribute.slug] || [],
          selectedItem?.slug,
          selectedLabel,
        );

        return (
          <StyledWrapper key={`${attribute.slug}-${attributeIndex}`}>
            {isQuickViewModal ? (
              <>
                <Eyebrow style={{ marginBottom: `${spacing[16]}` }}>
                  {selectAttributeCopy}
                </Eyebrow>
                <SingleSelectWrapper>
                  <SingleSelect
                    errorMessage={clickedATC && !displayValue ? isRequiredCopy : ''}
                    label={attribute.name}
                    items={itemsForSelect}
                    selectedItem={displayValue}
                    handleSelectedItemChange={toSelectItemHandler(
                      attribute,
                      attributeIndex,
                    )}
                    data-test-id={`configure-product-${attribute.slug}`}
                    required
                  />
                </SingleSelectWrapper>
              </>
            ) : (
              <SelectWithDesignSystemErrorMsg
                label={attribute.name}
                options={attribute.options.map(toFormSelectOption)}
                value={displayValue}
                onChange={toSelectItemHandler(attribute, attributeIndex)}
                data-test-id={`configure-product-${attribute.slug}`}
                required
              />
            )}
            {children}
          </StyledWrapper>
        );
      })}
      {isQuickViewModal ? (
        <Button
          width={'adaptive'}
          isLoading={loading}
          margin={`0 0 ${spacing[24]} 0;`}
          data-test-id={'Add To Cart'}
          type="submit"
        >
          {addToCartCTA}
        </Button>
      ) : (
        <Button
          color="dark"
          text={quickAddCTA}
          variant="solid"
          width="adaptive"
          size="small"
          type="submit"
          data-test-id="configure-product-submit"
          isLoading={loading}
          isDisabled={loading}
        />
      )}
    </StyledForm>
  );
};

const SingleSelectWrapper = styled.div`
  div > div > ul {
    max-height: 312px;
  }
`;

const StyledForm = styled.form`
  width: 100%;
`;

const QuickViewSelectWrapper = styled.div`
  text-align: left;
  margin-bottom: ${spacing[40]};
`;

const SelectWrapper = styled.div`
  margin-bottom: ${spacing[16]};
`;
