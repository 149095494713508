import {
  Eyebrow,
  Flex,
  Body,
  spacing,
  ResponsiveImage,
  white,
  pixelToRem,
} from '@pelotoncycle/design-system';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import type { Entry } from 'contentful';
import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '@peloton/internationalize-ui/components/LinkButton';
import BreakpointSwitchCSS from '@peloton/responsive/BreakpointSwitchCSS';
import { defaultTransition, media } from '@peloton/styles';
import type { TypeComponentGenericTextWithMediaFields } from '@page-builder/lib/types';
import getGenericTextNodes from '@page-builder/utils/getGenericTextNodes';
import { toImageProps } from '@page-builder/utils/helpers';

type Props = {
  item: Entry<TypeComponentGenericTextWithMediaFields>;
};

const CohortGridCard: React.FC<Props> = ({
  item: {
    fields: { ctas, text, media: icon },
  },
}) => {
  const { headline, body } = getGenericTextNodes(text, ['headline', 'body']);
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const image = toImageProps(icon);

  return (
    <StyledPlaceholder href={primaryCta?.url}>
      <Flex flexDirection="column" flexGrow={1} alignItems="center">
        {image && (
          <Flex justifyContent="center">
            <Image mobile={image.mobile} alt={image.alt} loading="lazy" />
          </Flex>
        )}
        <Eyebrow size="small" textAlign="center">
          {headline}
        </Eyebrow>
        <Body
          size="small"
          style={{ marginTop: spacing[24], flexGrow: 1 }}
          textAlign="center"
        >
          {body}
        </Body>
        <BreakpointSwitchCSS
          breakpoints={{
            mobile: undefined,
            desktop: primaryCta && (
              <LinkButton
                href={primaryCta.url}
                style={{ marginTop: spacing[24], marginBottom: spacing[24] }}
                variant="outline"
                color="dark"
                size="small"
              >
                {primaryCta.text}
              </LinkButton>
            ),
          }}
        />
      </Flex>
    </StyledPlaceholder>
  );
};

const Image = styled(ResponsiveImage)`
  img {
    max-width: 100%;
    ${media.tablet` 
       max-width: 160px;
    `}

    max-height: 100%;
  }
`;

export const StyledPlaceholder = styled.a`
  background-color: ${white};
  display: flex;
  padding: ${spacing[24]};
  border-radius: ${spacing[16]};

  ${media.tabletXLarge` 
      box-shadow: 0px 6px 24px 0px rgb(0 0 0 / 16%);
      border-radius: ${pixelToRem(3)};
  `}
`;

export const TileWrapper = styled.div.attrs({ role: 'group' })`
  cursor: pointer;
  outline: none;
  position: relative;
  text-align: left;
  background-color: ${white};

  a {
    outline: none;
  }

  [data-whatintent='keyboard'] &:focus,
  [data-whatintent='keyboard'] & a:focus {
    outline-offset: 1px;

    /* Set outline for non-webkit browsers */
    outline: Highlight 2px solid !important;

    /* Sets outline to default for webkit browsers */
    @media (-webkit-min-device-pixel-ratio: 0) {
      outline: -webkit-focus-ring-color auto 5px !important;
    }
  }

  [data-whatintent='mouse'] & {
    img {
      ${defaultTransition('transform')}
    }

    &:hover img {
      transform: scale(1.05);
    }
  }
  ${media.tabletXLarge` 
      box-shadow: 0px 6px 24px 0px rgb(0 0 0 / 16%);
  `}
`;
export default CohortGridCard;
