import {
  Support,
  grey,
  Eyebrow,
  Container,
  Flex,
  Icon,
  FlexChild,
  spacing,
  Headline,
  InfiniteCarousel,
} from '@pelotoncycle/design-system';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import type { PropsWithChildren } from 'react';
import React, { useMemo, useEffect } from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { I18N_LOCALE_TOGGLE } from '@peloton/app-config';
import { addCurrentLocaleToUrl } from '@peloton/internationalize/addCurrentLocaleToUrl';
import { Link } from '@peloton/next/components/Link';
import { LinkButton } from '@peloton/next/components/LinkButton';
import useTrackLinkClick from '@ecomm/analytics/hooks/useTrackLinkClick';
import type {
  TypeComponentCtaFields,
  TypeComponentGenericListFields,
} from '@page-builder/lib/types';
import { themes } from '@page-builder/themes';
import {
  getCtaFields,
  getTextFieldsWithRequiredKeys,
  hasCtaFields,
} from '@page-builder/utils/helpers/cms';
import { ctaLinkToUrlRequired } from '@page-builder/utils/helpers/cta';
import { toImageProps } from '@page-builder/utils/helpers/media';
import { nameToModuleId } from '@page-builder/utils/helpers/regex';
import Markdown from '@page-builder/utils/Markdown';
import type { Props as CardProps } from './Card';
import Card from './Card';

const ClassesCarousel: React.FC<
  React.PropsWithChildren<TypeComponentGenericListFields>
> = ({ items, text, ctas, name, theme = 'Grey 30' }) => {
  const { textColor, backgroundColor, headlineColor } = themes[theme];
  const { trackEvent } = useTracking();
  const { trackLinkClick } = useTrackLinkClick();
  const moduleId = nameToModuleId(name);
  const { support, eyebrow, headline } = getTextFieldsWithRequiredKeys(['eyebrow'], text);
  const primaryCta = ctas && ctas[0] && ctaExtractData(ctas[0]);
  const linkCards = useMemo(() => getCards(items), [items]);
  const cards = useMemo(
    () => [
      ...linkCards.map((card, index: number) => {
        return (
          <ClickableSpan key={`link_${card.title}`} data-test-id={'md-card'}>
            <Card
              {...card}
              LinkRenderer={getLinkRenderer({
                index,
                onClick: (href: string) =>
                  trackLinkClick({
                    href: primaryCta?.url || '',
                    parent: 'Classes Carousel V2',
                    additionalProps: {
                      linkTo: href,
                      linkName: primaryCta?.text,
                      unitName: primaryCta?.name,
                      parentType: 'Component: Generic List',
                    },
                  }),
              })}
              ImageContainer={getLinkImageContainer({
                index,
                href: card.url.includes('/classes') ? card.url : `/classes${card.url}`,
                onClick: () =>
                  trackEvent({
                    event: 'Clicked Discipline Icon',
                    properties: {
                      href: primaryCta?.url || '',
                      parent: 'Classes Carousel V2',
                      linkTo: card.url.includes('/classes')
                        ? card.url
                        : `/classes${card.url}`,
                      linkName: primaryCta?.text,
                      unitName: primaryCta?.name,
                      parentType: 'Component: Generic List',
                    },
                  }),
              })}
              textColor={textColor}
              headlineColor={headlineColor}
            >
              {card.icon && (
                <StyledIcon name={card.icon} height={40} primaryColor="currentColor" />
              )}
            </Card>
          </ClickableSpan>
        );
      }),
    ],
    [linkCards],
  );

  // Fix to prevent focusable elements from being inside aria-hidden elements
  useEffect(() => {
    const updateElementsFocusability = () => {
      const allSlides = document.querySelectorAll(
        `#${moduleId} [role="tabpanel"], #${moduleId} [aria-hidden]`,
      );

      allSlides.forEach(slide => {
        const isHidden = slide.getAttribute('aria-hidden') === 'true';

        // Handle the slide itself if it's an HTMLElement
        if (slide instanceof HTMLElement) {
          if (isHidden) {
            slide.setAttribute('tabindex', '-1');
          } else {
            slide.removeAttribute('tabindex');
          }
        }

        // Handle all focusable descendants
        const focusableElements = slide.querySelectorAll(
          'a, button, input, select, textarea, [tabindex], [contenteditable]',
        );

        focusableElements.forEach(el => {
          if (el instanceof HTMLElement) {
            if (isHidden) {
              el.setAttribute('tabindex', '-1');
            } else {
              // Only remove tabindex if it was set to -1
              if (el.getAttribute('tabindex') === '-1') {
                el.removeAttribute('tabindex');
              }
            }
          }
        });
      });
    };

    // Initial run
    updateElementsFocusability();

    // Set up a mutation observer to watch for changes to aria-hidden
    const observer = new MutationObserver(updateElementsFocusability);
    const carouselContainer = document.getElementById(moduleId);

    if (carouselContainer) {
      observer.observe(carouselContainer, {
        attributes: true,
        attributeFilter: ['aria-hidden'],
        childList: true,
        subtree: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [moduleId]);

  return (
    <Container
      verticalPadding={{ mobile: spacing[48], desktop: spacing[64] }}
      backgroundColor={backgroundColor}
      id={moduleId}
      data-test-id="classTeasersContainer"
      style={{ overflow: 'auto' }}
    >
      <Flex
        aria-live="polite"
        maxWidth="1220px"
        flexDirection="column"
        horizontalPadding={{
          mobile: spacing[24],
          tablet: spacing[64],
          desktop: spacing[40],
        }}
        gap={spacing[32]}
        centered
        textAlign="center"
        style={{ marginBottom: `${spacing[32]}`, boxSizing: 'content-box' }}
      >
        <Eyebrow is="p" size="large" textColor={headlineColor}>
          {eyebrow}
        </Eyebrow>
        {headline && (
          <Headline
            size="small"
            textColor={headlineColor}
            style={{ maxWidth: '600px', alignSelf: 'center' }}
          >
            {headline}
          </Headline>
        )}
      </Flex>
      <InfiniteCarousel
        buttonTopOffset="76px"
        itemWidth="288px"
        gap={spacing[24]}
        children={cards}
        theme={{
          background: 'transparent',
          bulletActive: 'rgb(101, 102, 106)',
          bulletInactive: 'rgb(168, 172, 177)',
          buttonBackground: `rgb(101, 102, 106, .6)`,
          buttonHoverBackground: `rgb(101, 102, 106, .8)`,
          buttonIconColor: 'rgb(255, 255, 255, .6)',
          buttonIconHoverColor: 'rgb(255, 255, 255, .8)',
        }}
      />
      <Flex
        verticalPadding={{ mobile: spacing[24], desktop: spacing[32] }}
        aria-live="polite"
        maxWidth="1220px"
        flexDirection="column"
        horizontalPadding={{
          mobile: spacing[24],
          tablet: spacing[64],
          desktop: spacing[40],
        }}
        gap={spacing[32]}
        centered
        alignItems="center"
        style={{ boxSizing: 'content-box' }}
      >
        {primaryCta && (
          <FlexChild>
            <LinkButton
              href={primaryCta.url}
              variant={primaryCta.variant}
              color={primaryCta.color}
              text={primaryCta.text}
              size="small"
              width="adjustable"
              onClick={() =>
                trackLinkClick({
                  href: primaryCta.url || '',
                  parent: 'Classes Carousel V2',
                  additionalProps: {
                    linkTo: primaryCta.url,
                    linkName: primaryCta.text,
                    unitName: primaryCta.name,
                    parentType: 'Component: Generic List',
                  },
                })
              }
            />
          </FlexChild>
        )}

        <Support size="small" textColor={textColor}>
          {support && <Markdown content={support} />}
        </Support>
      </Flex>
    </Container>
  );
};

type ItemCard<T> = {
  contentId: string;
} & CardProps &
  T;

type LinkCard = ItemCard<{ url: string; icon?: TypeComponentCtaFields['ctaIcon'] }>;

function getCards(items: TypeComponentGenericListFields['items']): LinkCard[] {
  return items.reduce((linkCards: LinkCard[], item) => {
    const { body: caption, label: newLabel } = getTextFieldsWithRequiredKeys(
      ['body'],
      item.fields.text,
    );
    const image = toImageProps(item.fields.media);

    if (!image) {
      return linkCards;
    }

    if (hasCtaFields('link', item.fields.ctas)) {
      const {
        primaryCta: { text: title },
      } = getCtaFields('link', item.fields.ctas);
      const url = ctaLinkToUrlRequired(item.fields.ctas?.[0]);
      const icon = item.fields.ctas?.[0].fields.ctaIcon;
      const contentId = item.fields.name;

      linkCards.push({ contentId, caption, newLabel, image, title, url, icon });
    }

    return linkCards;
  }, []);
}

const getLinkImageContainer = ({
  index,
  href,
  onClick,
}: {
  index: number;
  href: string;
  onClick: VoidFunction;
  // eslint-disable-next-line react/display-name
}): React.FC<React.PropsWithChildren<unknown>> => ({ children }) => {
  return (
    <Link href={href} hasUnderline={false}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div onClick={onClick} data-test-id={`linkImage_${index}`}>
        {children}
      </div>
    </Link>
  );
};

const getLinkRenderer = ({
  index,
  onClick,
}: {
  index: number;
  onClick: (href: string) => void;
}): // eslint-disable-next-line react/display-name
React.FC<React.PropsWithChildren<PropsWithChildren<{ href: string }>>> => ({
  href,
  children,
}) => {
  return (
    // This is ok to leave as anchor for now because it is an external link in contentful
    <a
      href={addCurrentLocaleToUrl(href, I18N_LOCALE_TOGGLE)}
      onClick={() => onClick(href)}
      data-test-id={`link_${index}`}
    >
      {children}
    </a>
  );
};

export default ClassesCarousel;

const StyledIcon = styled(Icon)`
  padding: ${spacing[8]};
  background-color: ${grey[40]};
  border-radius: 50%;
  overflow: visible;
  transition: all 0.3s ease-in-out 0s;
`;

// Modified to only enable pointer-events when using mouse
const ClickableSpan = styled.span`
  @media (hover: hover) and (pointer: fine) {
    pointer-events: auto !important;
    & > * {
      pointer-events: auto !important;
    }
  }
`;
