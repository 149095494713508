import { useAddCfuToCart } from '@ecomm/commercetools/hooks/useAddCfuToCart';
import { mapSelectionToLegacyOptions } from '@ecomm/commercetools/mappers';
import type { SelectedOption } from '@ecomm/graphql/types.generated';
import { useAddItemToCart } from '@ecomm/pg-checkout-commercetools/utils/cartUtils';
import { Type } from '@ecomm/pg-checkout-commercetools/utils/types';
import type {
  CommerceToolsAccessory,
  CommerceToolsBundle,
  CommerceToolsCFU,
  CommerceToolsSparePart,
} from '@ecomm/product-recommendations/models/Product';
import { toQuickAddProductFunction } from '@ecomm/product-recommendations/utils/quick-add/toQuickAddProductFunction';
import type { QuickAddMutationHook } from '@ecomm/product-recommendations/utils/quick-add/types';
import { defaultHookOptions } from '@ecomm/product-recommendations/utils/quick-add/types';
import toProductOptionIdsForUnconfigurableCfu from '@ecomm/product-recommendations/utils/toProductOptionIdsForUnconfigurableCfu';
import type { ProductSelection } from '@ecomm/shop/graphql/useAddBundleToCartMutation';
import { useAddProductToCartMonolith } from '@ecomm/shop/graphql/useAddProductToCartMonolith';

export const useQuickAddAccessoryMutationCommerceTools: QuickAddMutationHook<CommerceToolsAccessory> = (
  product,
  opts = defaultHookOptions,
) => {
  // TODO: Update useAddProductToCartMonolith hook to support not opening cart on success
  const {
    addToCart: addAccessoryToMonolithCart,
    atcResult,
  } = useAddProductToCartMonolith();
  const { addItemToCart: addItemToCTCart, result } = useAddItemToCart();

  const loading = atcResult.loading || result.loading;
  const error = atcResult.error || result.error;

  const addToCart = toQuickAddProductFunction(
    async (selections: SelectedOption[] = []) => {
      const [legacyOptionItem] = mapSelectionToLegacyOptions(
        product.ctProduct,
        selections,
      );
      if (!legacyOptionItem) {
        throw new Error('Could not find option for selection');
      }

      await addItemToCTCart({
        type: Type.ACCESSORY,
        sku: legacyOptionItem.sku,
        callBack: async () => await addAccessoryToMonolithCart(legacyOptionItem),
        item: product.ctProduct,
      });
    },
  );

  return [
    addToCart,
    {
      loading,
      error,
    },
  ] as const;
};

export const useQuickAddSparePartMutationCommerceTools: QuickAddMutationHook<CommerceToolsSparePart> = (
  product,
  opts = defaultHookOptions,
) => {
  // TODO: Update useAddProductToCartMonolith hook to support not opening cart on success
  const {
    addToCart: addSparePartToMonolithCart,
    atcResult,
  } = useAddProductToCartMonolith();
  const { addItemToCart: addItemToCTCart, result } = useAddItemToCart();

  const loading = atcResult.loading || result.loading;
  const error = atcResult.error || result.error;

  const addToCart = toQuickAddProductFunction(
    async (selections: SelectedOption[] = []) => {
      const [legacyOptionItem] = mapSelectionToLegacyOptions(
        product.ctProduct,
        selections,
      );
      if (!legacyOptionItem) {
        throw new Error('Could not find option for selection');
      }

      await addItemToCTCart({
        type: Type.SPARE_PART,
        sku: legacyOptionItem.sku,
        callBack: async () => await addSparePartToMonolithCart(legacyOptionItem),
        item: product.ctProduct,
      });
    },
  );

  return [
    addToCart,
    {
      loading,
      error,
    },
  ] as const;
};

export const useQuickAddBundleMutationCommerceTools: QuickAddMutationHook<CommerceToolsBundle> = (
  product,
  opts = defaultHookOptions,
) => {
  const { addToCart: addBundleToMonolithCart, atcResult } = useAddProductToCartMonolith();
  const { addItemToCart: addItemToCTCart, result } = useAddItemToCart();

  const loading = atcResult.loading || result.loading;
  const error = atcResult.error || result.error;

  const addToCart = toQuickAddProductFunction(
    async (selections: ProductSelection[] = []) => {
      await addItemToCTCart({
        type: Type.BUNDLE_ACCESSORY,
        sku: product.ctProduct.sku,
        bundleObject: {
          bundle: product.ctProduct,
          productSelection: selections,
        },
        callBack: async () =>
          await addBundleToMonolithCart(product.ctProduct, selections),
      });
    },
  );

  return [
    addToCart,
    {
      loading,
      error,
    },
  ] as const;
};

export const useQuickAddCfuPackageMutationCommerceTools: QuickAddMutationHook<CommerceToolsCFU> = (
  product,
  opts = defaultHookOptions,
) => {
  const analyticsProperties = {
    bundleType: product.bundleType,
    bundlePackage: product.ctProduct,
    hasAccessory: false,
    hasAccessoryBundle: false,
    addedFromCart: false,
    hasGuide: false,
    // TODO: support hasOPC and hasCPO
    hasOPC: false,
    hasCPO: false,
  };
  const [addCfuToMonolithCart, atcResult] = useAddCfuToCart(
    {
      upsellIds: [],
      upsellBundles: [],
    },
    analyticsProperties,
  );
  const { addItemToCart: addItemToCTCart, result } = useAddItemToCart();

  const loading = atcResult.loading || result.loading;
  const error = atcResult.error || result.error;

  const addToCart = toQuickAddProductFunction(async () => {
    const productOptionIds = toProductOptionIdsForUnconfigurableCfu(product);
    const productSelections = [
      {
        product: product.cfuProductSlug,
        selections: [],
      },
    ];

    await addItemToCTCart({
      type: Type.CT_CFU,
      sku: product.slug,
      cfuPackage: {
        isSelectionValid: true,
        package: product.ctProduct,
        productSelection: productSelections,
        analyticsProperties: {
          bundleType: product.bundleType,
          bundlePackage: product.ctProduct,
          hasAccessory: false,
          hasAccessoryBundle: false,
          addedFromCart: false,
          hasGuide: false,
          // TODO: support hasOPC and hasCPO
          hasOPC: false,
          hasCPO: false,
        },
      },
      callBack: async () =>
        await addCfuToMonolithCart(
          {
            bundleSlug: product.slug,
            packageId: product.packageId,
            warrantySelection: {
              product: product.warrantySlug,
              selections: [],
            },
            productSelections,
            productOptionIds,
            hasTradeIn: false,
          },
          opts.autoOpenCartPanelOnSuccess,
        ),
    });
  });

  return [
    addToCart,
    {
      loading,
      error,
    },
  ] as const;
};
