import { useApolloClient } from '@apollo/client';
import { useClient } from '@peloton/api/ClientContext';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import { toLocaleFromTLD } from '@peloton/internationalize/models/locale';
import { toStoreSlug } from '@peloton/stores/Store';
import {
  useOpenCartPanel,
  useSetHasItemJustBeenAdded,
  useSetCartException,
} from '@ecomm/cart-next/context/CartContext';
import { getErrorCode as getAddToCartErrorCode } from '@ecomm/cart/models/AddToCartErrors';
import { toCommercetoolsClient } from '@ecomm/commercetools/apollo';
import { fromLocale, toCurrencyfromLocale } from '@ecomm/graphql-bridge';
import { useLocalResolvers } from '@ecomm/graphql/useLocalResolvers';
import type { Slug } from '@ecomm/models';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import { addToCartResolver } from './addToCartResolver';
import { useAddBundleToCartAnalytics } from './useAddBundleToCartAnalytics';
import useAddPackageToCartAnalytics from './useAddPackageToCartAnalytics';
import { useAddPackageToCartMutation } from './useAddPackageToCartMutation';
import type { Variables as BaseVariables } from './useAddPackageToCartMutation';

type Variables = Omit<
  BaseVariables,
  'locale' | 'currency' | 'slug' | 'upsellIds' | 'upsellBundles'
>;

export const dataSourceCMS = 'cms';

export const useAddPackageToCart = (
  bundleSlug: Slug,
  bundleType: BundleType,
  hasAccessory?: boolean,
  hasAccessoryBundle?: boolean,
  accessoryName?: string,
  addedFromCart?: boolean,
  hasGuide?: boolean,
  hasOPC?: boolean,
  hasCPO?: boolean,
  upsellIds: [productId: string, productOptionId?: string][] = [],
  upsellBundles: { bundleId: string; productOptions: string[] }[] = [],
) => {
  const baseLocale = toLocaleFromTLD();
  const locale = fromLocale(baseLocale);
  const currency = toCurrencyfromLocale(baseLocale);
  const storeSlug = toStoreSlug();
  const restClient = useClient();
  const apolloClient = useApolloClient();
  const ctClient = toCommercetoolsClient();
  const {
    errorReporter: { reportError },
  } = useErrorReporter();

  // methods
  useLocalResolvers(addToCartResolver);
  const [addPackageToCart, result] = useAddPackageToCartMutation();
  const openCartPanel = useOpenCartPanel();
  const setHasItemJustBeenAddedToCart = useSetHasItemJustBeenAdded();
  const setCartException = useSetCartException();
  const { trackAddBundleToCart } = useAddBundleToCartAnalytics();
  useAddPackageToCartAnalytics(
    bundleSlug,
    bundleType,
    result?.data?.addPackageToCart,
    hasAccessory,
    hasAccessoryBundle,
    accessoryName,
    addedFromCart,
    hasGuide,
    hasOPC,
    hasCPO,
  );

  const addToCart = async (
    variables: Omit<Variables, 'locale' | 'currency' | 'slug'>,
    shouldOpenCart: boolean = true,
    bundleAnalyticsProperties?: Pick<BundleAccessoryType, 'id' | 'name' | 'price'>[],
  ) => {
    try {
      const { data } = await addPackageToCart({
        context: { apolloClient, restClient, ctClient },
        variables: {
          ...variables,
          locale,
          currency,
          slug: storeSlug,
          upsellIds: upsellIds.map(([, poid]) => poid).filter(Boolean) as string[],
          upsellBundles,
        },
      });

      if (!data) return;
      if (bundleAnalyticsProperties && bundleAnalyticsProperties.length) {
        bundleAnalyticsProperties.forEach(({ id, name, price }) => {
          trackAddBundleToCart(
            {
              id,
              name,
              price,
            },
            data.addPackageToCart,
          );
        });
      }
      setHasItemJustBeenAddedToCart(true);
      if (shouldOpenCart) {
        openCartPanel();
      }
    } catch (e) {
      const networkError = e.networkError?.response?.data;
      const errorMsg = getAddToCartErrorCode(networkError?.errorCode);
      setCartException(errorMsg);
      const tags = {
        userAction: 'atc',
        dataSource: dataSourceCMS,
      };
      reportError(e, { errorMsg, networkError, tags });
      throw e;
    }
  };

  return [addToCart, result] as const;
};
