import { Container, spacing } from '@pelotoncycle/design-system';
import { ctaExtractData } from '@pelotoncycle/page-builder';
import React from 'react';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import type { TypeComponent_overview } from '@page-builder/lib/types';
import CFUPackageProvider from '@page-builder/modules/Overview/CFUPackageProvider';
import PromoProvider from '@page-builder/modules/Overview/PromoProvider';
import { ShopDrawersContextProvider } from '@page-builder/modules/Overview/ShopDrawersContextProvider';
import { DrawerContentContextProvider } from './DrawerContentContext';
import Overview from './Overview';
import OverviewHeadband from './OverviewHeadband';
import { ProductConfigurationContextProvider } from './ProductConfigurationContext';
import StructuredDataWrapper from './StructuredData';
import { UpsellAccessoryContextProvider } from './UpsellAccessoryContext';

export const OverviewContainer: React.FC<
  React.PropsWithChildren<TypeComponent_overview>
> = ({
  fields: {
    name,
    reviews,
    ribbon,
    product,
    tabs,
    buyrentToggleContainerTitleLine: waysToShopIntro,
    valueProps,
    nearestShowroom,
    shopDrawers,
    gallery,
    breadcrumbs,
    postalCodeInput,
    availabilityLeadGen,
    soldOutLeadGen,
    crossShopToggle,
    pbxPackageUpsell,
  },
}) => {
  const breadcrumbProps = breadcrumbs?.map(breadcrumb => ctaExtractData(breadcrumb));
  return (
    <ShopDrawersContextProvider shopDrawers={shopDrawers} product={product}>
      <CFUPackageProvider>
        <PromoProvider>
          <UpsellAccessoryContextProvider product={product}>
            <OverviewHeadband product={product} />
            <Container
              data-test-id="pb-overview-container"
              padding={{
                mobile: `0 ${spacing[16]}`,
                tablet: `0 ${spacing[64]}`,
                desktop: `${spacing[48]} ${spacing[40]} 0`,
              }}
            >
              <StyledTwoColumnLayout
                margin="0 auto"
                maxWidth="1224px"
                display={{ desktop: 'flex' }}
              >
                <DrawerContentContextProvider product={product}>
                  <ProductConfigurationContextProvider>
                    <StructuredDataWrapper />
                    <Overview
                      name={name}
                      gallery={gallery}
                      reviews={reviews}
                      ribbon={ribbon}
                      shopSectionTitle={waysToShopIntro}
                      product={product}
                      valueProps={valueProps}
                      tabs={tabs}
                      nearestShowroom={nearestShowroom}
                      breadcrumbs={breadcrumbProps}
                      postalCodeInputFields={postalCodeInput?.fields}
                      emailLeadGenFields={availabilityLeadGen?.fields}
                      crossShopToggle={crossShopToggle}
                      soldOutLeadGenFields={soldOutLeadGen?.fields}
                      pbxPackageUpsell={pbxPackageUpsell}
                    />
                  </ProductConfigurationContextProvider>
                </DrawerContentContextProvider>
              </StyledTwoColumnLayout>
            </Container>
          </UpsellAccessoryContextProvider>
        </PromoProvider>
      </CFUPackageProvider>
    </ShopDrawersContextProvider>
  );
};

const StyledTwoColumnLayout = styled(Container)`
  // TODO: update to remove legacy desktop breakpoints brought over from @ecomm Overview;
  // There should only be one desktop breakpoint used.
  ${media.desktopLarge`
    gap: ${spacing[40]};
  `}
  ${media.desktopXLarge`
    gap: ${spacing[64]};
  `}
`;
