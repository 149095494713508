import React from 'react';
import type { QuickAddProductFunction } from '@ecomm/product-recommendations/utils/quick-add/types';
import { isProductBundle } from '../models/checkProductType';
import type { PriceOrPriceRange, Product, Attribute, Bundle } from '../models/Product';
import type { SelectionOptions } from './productUtils';
import {
  createBundleSelections,
  everyAttributeConfigured,
  getBundlePriceRangeWithVariants,
  getVariantForAttributeSelection,
} from './productUtils';

type AttributeSlugMap = {
  [slug: string]: string | undefined;
};

export type ProductSelection = {
  product: string;
  selections: {
    attribute: string;
    option: string;
  }[];
};

export type ProductSelectionsArray = ProductSelection[];

export type SelectionType = AttributeSlugMap | ProductSelectionsArray;

type Options = {
  product: Product;
  onClose: (hasAddedToCart?: boolean) => void;
  quickAddProduct: QuickAddProductFunction;
};

type SelectionEvent = React.FormEvent<HTMLSelectElement> | SelectionOptions;

const isFormEvent = (e: SelectionEvent): e is React.FormEvent<HTMLSelectElement> => {
  return e?.hasOwnProperty('currentTarget');
};

export const useConfigureProductFormData = ({
  product,
  onClose,
  quickAddProduct,
}: Options) => {
  const [displayedPrice, setDisplayedPrice] = React.useState<PriceOrPriceRange>(
    product.price,
  );
  const [selections, setSelections] = React.useState<SelectionType>(
    isProductBundle(product) ? createBundleSelections(product) : {},
  );

  let incompleteAttributes: Attribute[];

  if (isProductBundle(product)) {
    incompleteAttributes = product.attributes.filter(({ productSlug }) => {
      return (
        (selections as ProductSelectionsArray).find(s => s.product === productSlug) ===
        undefined
      );
    });
  } else {
    incompleteAttributes = product.attributes.filter(
      ({ slug }) => selections[slug] === undefined,
    );
  }

  const isFormComplete = incompleteAttributes.length === 0;

  const onFormSubmit = async () => {
    if (isFormComplete) {
      const selectedOptions = isProductBundle(product)
        ? (selections as ProductSelection[])
        : product.attributes.map(({ slug }) => ({
            attribute: slug,
            option: selections[slug]!,
          }));
      const success = await quickAddProduct(selectedOptions);

      if (success) {
        onClose(true);
      }
    }
  };

  const toSelectItemHandler = (attribute: Attribute, attributeIndex: number) => (
    e: SelectionEvent,
  ) => {
    // if not a react form (DS dropdown), e can be undefined when selecting the empty option
    const value = isFormEvent(e) ? e.currentTarget.value : e?.value;

    if (isProductBundle(product)) {
      handleBundleItemSelection(attribute, attributeIndex, value);
      return;
    }

    setSelections(oldSelections => {
      return {
        ...oldSelections,
        [attribute.slug]: value,
      };
    });

    const newDisplayedPrice =
      getVariantForAttributeSelection(product, attribute, value)?.price.amount ??
      product.price;

    setDisplayedPrice(newDisplayedPrice);
  };

  const handleBundleItemSelection = (
    attribute: Attribute,
    attributeIndex: number,
    value: string,
  ) => {
    setSelections((oldSelections: ProductSelectionsArray) => {
      const currentSelection = oldSelections[attributeIndex];
      currentSelection.selections = [
        {
          attribute: attribute.slug,
          option: value,
        },
      ];

      if (everyAttributeConfigured(oldSelections)) {
        const newDisplayPrice = getBundlePriceRangeWithVariants(
          product as Bundle,
          [...oldSelections] as ProductSelectionsArray,
        );
        setDisplayedPrice(newDisplayPrice);
      } else {
        setDisplayedPrice(product.price);
      }

      return [...oldSelections];
    });
  };

  return {
    displayedPrice,
    selections,
    onFormSubmit,
    toSelectItemHandler,
  };
};
