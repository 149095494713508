import type { ButtonProps } from '@pelotoncycle/design-system';
import { Button } from '@pelotoncycle/design-system';
import React, { Suspense, useEffect } from 'react';
import { useClient } from '@peloton/api/ClientContext';
import { useErrorReporter } from '@peloton/error-reporting/useErrorReporter';
import { useFormattedText } from '@peloton/next/hooks/useFormattedText';
import { useClearCartException } from '@ecomm/cart-next/context/CartContext';
import { fetchCart } from '@ecomm/cart/api/loadCart';
import {
  useAddCfuToCart,
  dataSourceCT,
} from '@ecomm/commercetools/hooks/useAddCfuToCart';
import type { ProductVariant } from '@ecomm/graphql/types.generated';
import type { Money } from '@ecomm/models';
import { toDollars } from '@ecomm/models';
import { useAddItemToCart } from '@ecomm/pg-checkout-commercetools/utils/cartUtils';
import { Type } from '@ecomm/pg-checkout-commercetools/utils/types';
import type { BundleAccessoryType } from '@ecomm/pg-shop-accessories/models';
import { isRentalSlug } from '@ecomm/rentals/models';
import type { CfuProductItem } from '@ecomm/shop-configuration';
import type { CommercetoolsPackage } from '@ecomm/shop-configuration/models';
import type { PackageBySlugOptionalWarrantyQuery } from '@ecomm/shop/graphql/PackageBySlugOptionalWarrantyQuery.generated';
import type { PackageBySlugQuery } from '@ecomm/shop/graphql/PackageBySlugQuery.generated';
import {
  useAddPackageToCart,
  dataSourceCMS,
} from '@ecomm/shop/graphql/useAddPackageToCart';
import type { BundleType } from '@ecomm/shop/models/BundleType';
import type { TypeComponentCtaFields } from '@page-builder/lib/types';
import { useProductConfigurationContext } from '@page-builder/modules/Overview/ProductConfigurationContext';
import type { ProductConfigurationContextProps } from '@page-builder/modules/Overview/ProductConfigurationContext';
import { usePromoContext } from '@page-builder/modules/Overview/PromoProvider';
import type { InViewRef } from '@page-builder/modules/Overview/ShopContext';
import { useShopContext } from '@page-builder/modules/Overview/ShopContext';
import { useUpsellAccessoryContext } from '@page-builder/modules/Overview/UpsellAccessoryContext';
import { useCFUPackageContext } from '@page-builder/modules/Overview/useCFUPackageContext';
import {
  allBundleProductsHaveVariants,
  isAccessoryBundle,
  isConfigurable,
  toAddCTPackageToCartParams,
  toAddPackageMutationParams,
  toVariantForAccessory,
} from '@page-builder/modules/Overview/utils';
import getUpsells from './getUpsells';

type Props = {
  cta: TypeComponentCtaFields;
  onClick?: () => void;
  size?: ButtonProps['size'];
  width?: ButtonProps['width'];
  activeCtaRef?: InViewRef;
};

type InnerProps = Props & {
  cfuPackage:
    | NonNullable<
        | PackageBySlugQuery['catalog']['packageBySlug']
        | PackageBySlugOptionalWarrantyQuery['catalog']['packageBySlugOptionalWarranty']
      >
    | CommercetoolsPackage;
  bundleType: BundleType;
};

type ButtonViewProps = Props & {
  loading: boolean;
  totalPrice?: Money;
  showPrice?: boolean;
};

type AddToCartCtaButtonProps = Pick<InnerProps, 'cta' | 'cfuPackage'> &
  ButtonViewProps & {
    handleClick: (cb: () => Promise<void>) => void;
    showPrice?: boolean | undefined;
    bundleDataForAnalytics: Pick<BundleAccessoryType, 'id' | 'name' | 'price'>[];
  };

const ButtonView: React.FC<React.PropsWithChildren<ButtonViewProps>> = ({
  cta,
  loading,
  onClick,
  size = 'medium',
  width = 'adaptive',
  activeCtaRef,
  totalPrice = 0,
  showPrice,
}) => {
  const { text, color, variant } = cta;
  const formattedPrice = useFormattedText('{price, number, currency}', {
    price: toDollars(totalPrice),
  });
  const buttonText = showPrice ? `${text} – ${formattedPrice}` : text;

  return (
    <Button
      onClick={onClick}
      color={color}
      variant={variant}
      size={size}
      width={width}
      isLoading={loading}
      ref={activeCtaRef}
      data-test-id="addToCart"
    >
      {buttonText}
    </Button>
  );
};

const AddToCartCtaCommercetools: React.FC<
  React.PropsWithChildren<
    AddToCartCtaButtonProps & {
      addCTPackageToCartParams: ReturnType<typeof toAddCTPackageToCartParams>;
      packageProductCartData: ReturnType<
        ProductConfigurationContextProps['getPackageProductCartData']
      >;
    }
  >
> = ({
  cfuPackage,
  packageProductCartData,
  addCTPackageToCartParams,
  handleClick,
  loading,
  totalPrice,
  showPrice,
  bundleDataForAnalytics,
  ...restProps
}) => {
  const { slug: packageSlug, id: packageId } = cfuPackage;
  const { upsellIds, upsellBundles, ...analyticsProperties } = addCTPackageToCartParams;

  const [addCTPackageToCart] = useAddCfuToCart(
    { upsellIds, upsellBundles },
    analyticsProperties,
  );

  const onAddPackageToCart = async () => {
    try {
      const commercetoolsCFUPackage = cfuPackage as CommercetoolsPackage;
      const warranty = commercetoolsCFUPackage.warranty as CfuProductItem | undefined;

      const productOptionIds = [
        warranty?.variants[0].id,
        (commercetoolsCFUPackage.connectedFitnessUnit.variants[0] as ProductVariant).id,
        ...packageProductCartData.productOptionIds,
      ].filter(Boolean) as string[];

      await addCTPackageToCart(
        {
          bundleSlug: packageSlug,
          packageId: packageId || '',
          warrantySelection: warranty
            ? {
                product: warranty.slug,
                selections: [],
              }
            : undefined,
          productSelections: [
            {
              product: cfuPackage.connectedFitnessUnit.slug,
              selections: [],
            },
            ...packageProductCartData.productSelections,
          ],
          hasTradeIn: false,
          productOptionIds,
        },
        true,
        bundleDataForAnalytics,
      );
    } catch (e) {
      // This exception is handled through the cart exception mechanism
    }
  };

  return (
    <ButtonView
      {...restProps}
      totalPrice={totalPrice}
      loading={loading}
      onClick={() => handleClick(onAddPackageToCart)}
      showPrice={showPrice}
    />
  );
};

const AddToCartCtaCMS: React.FC<
  React.PropsWithChildren<
    AddToCartCtaButtonProps & {
      bundleType: BundleType;
      selectedAccessories: BundleAccessoryType[];
    }
  >
> = ({
  cfuPackage,
  handleClick,
  loading: commercetoolsATCLoading,
  totalPrice,
  showPrice,
  bundleDataForAnalytics,
  selectedAccessories,
  bundleType,
  ...restProps
}) => {
  const {
    slug: packageSlug,
    accessories,
    connectedFitnessUnit: cfu,
    id: packageId,
    warranty,
  } = cfuPackage;
  const { accessoryVariants } = useUpsellAccessoryContext();

  const mutationParams = toAddPackageMutationParams(
    packageSlug,
    bundleType,
    selectedAccessories,
    accessoryVariants,
  );

  const [addPackageToCart, { loading }] = useAddPackageToCart(...mutationParams);

  const onAddPackageToCart = async () => {
    // Accessory slugs need to be added for rental packages, since the delivery fee and membership need to be added separately
    const packageAccessorySlugs = accessories.map(({ slug }) => slug);
    const productSlugs = [cfu.slug, ...packageAccessorySlugs];

    try {
      await addPackageToCart(
        {
          bundleSlug: packageSlug,
          packageId: packageId,
          productSelections: productSlugs.map(slug => ({
            product: slug,
            selections: [],
          })),
          warrantySelection: warranty
            ? {
                product: warranty?.slug,
                selections: [],
              }
            : undefined,
          hasTradeIn: false,
        },
        true,
        bundleDataForAnalytics,
      );
    } catch (e) {
      // This exception is handled through the cart exception mechanism
    }
  };

  return (
    <ButtonView
      {...restProps}
      totalPrice={totalPrice}
      loading={loading || commercetoolsATCLoading}
      onClick={() => handleClick(onAddPackageToCart)}
      showPrice={showPrice}
    />
  );
};

const AddToCartCtaButton: React.FC<React.PropsWithChildren<InnerProps>> = ({
  cfuPackage,
  onClick,
  bundleType,
  ...restProps
}) => {
  const {
    slug: packageSlug,
    connectedFitnessUnit: cfu,
    price: { amount: cfuPrice },
  } = cfuPackage;

  const { bundlePromo } = usePromoContext();

  const cfuDiscountDollars = bundlePromo?.fields?.couponDiscount || 0;
  const cfuDiscount = cfuDiscountDollars * 100;

  const {
    accessoryVariants,
    upsellAccessoriesSelected,
    setAccessoryMisconfigured,
    upsellAccessories = [],
  } = useUpsellAccessoryContext();
  const {
    getPackageProductCartData,
    setMisconfiguredProducts: setMisconfiguredPackageProducts,
    getMisconfiguredPackageProducts,
  } = useProductConfigurationContext();

  const selectedAccessories = upsellAccessories.filter(accessory =>
    upsellAccessoriesSelected.includes(accessory.slug),
  );

  const hasSelectedAccessory = selectedAccessories.length > 0;

  const bundleDataForAnalytics = selectedAccessories.reduce((totalBundles, accessory) => {
    if (!isAccessoryBundle(accessory)) return totalBundles;
    return [
      ...totalBundles,
      {
        id: accessory.id,
        name: accessory.name,
        price: accessory.price,
      },
    ];
  }, []);

  const unconfiguredAccessories = selectedAccessories.filter(accessory => {
    if (isAccessoryBundle(accessory)) {
      return (
        isConfigurable(accessory) &&
        !allBundleProductsHaveVariants(accessory, accessoryVariants)
      );
    } else {
      const variant = toVariantForAccessory(accessory, accessoryVariants);
      return isConfigurable(accessory) && !variant;
    }
  });

  const unconfiguredPackageProducts = getMisconfiguredPackageProducts();

  const isExpandedWithoutSelection =
    unconfiguredPackageProducts.length > 0 || unconfiguredAccessories.length > 0;

  const isRental = isRentalSlug(packageSlug);
  const showPriceWithinCta =
    !isRental && hasSelectedAccessory && !isExpandedWithoutSelection;

  const accessoryPrice = selectedAccessories.reduce((total, accessory) => {
    // bundles should not sum the price of accessories, instead use the listed price
    // a la carte accessories should use the variant price
    const listedPrice = isAccessoryBundle(accessory)
      ? accessory.price.amount
      : toVariantForAccessory(accessory, accessoryVariants)?.price.amount;
    return total + (accessory.discountPrice?.amount || listedPrice || 0);
  }, 0);

  const totalPrice = accessoryPrice + cfuPrice - cfuDiscount;

  const clearCartException = useClearCartException();

  // TODO: use new add-to-cart flow helper

  const packageProductCartData = getPackageProductCartData();
  const { shouldUseCTProvider: isCommerceToolsATCFlowActive } = useCFUPackageContext();

  const {
    errorReporter: { reportError },
  } = useErrorReporter();
  const { upsellIds, upsellBundles, ...analyticsProperties } = toAddCTPackageToCartParams(
    packageSlug,
    bundleType,
    selectedAccessories,
    accessoryVariants,
    cfuPackage as CommercetoolsPackage,
  );

  const {
    addItemToCart,
    result: { loading: commercetoolsATCLoading },
  } = useAddItemToCart();

  const handleClick = async (callBack: Function) => {
    try {
      onClick?.();
      clearCartException();
      for (const accessory of unconfiguredAccessories) {
        setAccessoryMisconfigured(accessory.slug, true);
      }
      if (unconfiguredPackageProducts.length) {
        setMisconfiguredPackageProducts(unconfiguredPackageProducts);
      }

      if (isExpandedWithoutSelection) {
        return;
      }

      const productSelections = [
        {
          product: cfu.slug,
          selections: [],
        },
        ...packageProductCartData.productSelections,
      ];

      // because of the isExpandedWithoutSelection base case, we can assume that is there is a valid variant if an accessory is selected
      // blocks bundles

      const upsell = getUpsells(selectedAccessories, accessoryVariants);

      await addItemToCart({
        type: Type.CT_CFU,
        sku: packageSlug,
        cfuPackage: {
          isSelectionValid: true,
          package: cfuPackage as CommercetoolsPackage,
          productSelection: productSelections,
          ...(upsell.length > 0 && {
            upsell,
          }),
          analyticsProperties,
        },
        callBack,
      });
    } catch (e) {
      // report any errors which occur outside of onAddPackageToCart()

      const dataSource = isCommerceToolsATCFlowActive ? dataSourceCT : dataSourceCMS;
      const tags = {
        userAction: 'atc',
        dataSource,
      };
      reportError(e, { tags });
    }
  };

  const sharedButtonProps = {
    cfuPackage,
    handleClick,
    loading: commercetoolsATCLoading,
    totalPrice,
    bundleDataForAnalytics,
    showPrice: showPriceWithinCta,
  };

  return isCommerceToolsATCFlowActive ? (
    <AddToCartCtaCommercetools
      {...restProps}
      {...sharedButtonProps}
      packageProductCartData={packageProductCartData}
      addCTPackageToCartParams={{ upsellIds, upsellBundles, ...analyticsProperties }}
    />
  ) : (
    <AddToCartCtaCMS
      {...restProps}
      {...sharedButtonProps}
      bundleType={bundleType}
      selectedAccessories={selectedAccessories as BundleAccessoryType[]}
    />
  );
};

const AddToCartCta: React.FC<React.PropsWithChildren<Props>> = props => {
  const { productPackageLoading, productPackage, productBundleType } = useShopContext();
  const client = useClient();

  useEffect(() => {
    // Making an api call to get cart summary in the background
    // this will create a locale specific cart (if it doesn't already exist)
    // without this call, a US locale cart is created by default
    // which leads to issues in other locales for NextJS ATC
    // We can ignore any errors resulting from this
    if (client) {
      fetchCart(client).catch(e => console.error(e));
    }
  }, [client]);

  if (productPackageLoading) {
    return <ButtonView {...props} loading />;
  }

  if (productPackage && productBundleType) {
    return (
      <Suspense fallback={null}>
        <AddToCartCtaButton
          {...props}
          cfuPackage={productPackage}
          bundleType={productBundleType}
        />
      </Suspense>
    );
  }

  // Does this need an error state?
  return null;
};

export default AddToCartCta;
