import type { ApolloError } from 'apollo-client';
import type { SelectedOption } from '@ecomm/graphql/types.generated';
import type { ProductSelection } from '@ecomm/shop/graphql/useAddBundleToCartMutation';

export type QuickAddProductFunction = (
  selections?: SelectedOption[] | ProductSelection[],
) => Promise<boolean>;

export type QuickAddMutation = Readonly<
  [
    QuickAddProductFunction,
    {
      loading: boolean;
      error?: ApolloError;
    },
  ]
>;

export type HookOptions = {
  autoOpenCartPanelOnSuccess: boolean;
};

export type QuickAddMutationHook<T> = (
  product: T,
  opts?: HookOptions,
) => QuickAddMutation;

export const defaultHookOptions: HookOptions = {
  autoOpenCartPanelOnSuccess: true,
};
